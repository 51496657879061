import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-popup',
  templateUrl: './map-popup.component.html',
  styleUrls: ['./map-popup.component.css']
})
export class MapPopupComponent implements OnInit{

  constructor() { }

  ngOnInit(): void {
  }


}
