import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// @sajib
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WeatherForecastService {



  link_header = "https://nlas.bdservers.site/api/";

  get_district  = this.link_header + "get_district.php";
  get_upazila   = this.link_header + "get_upazila.php";

  constructor(private http:HttpClient) { }

  getDistrict() {
    return this.http.get<any>(environment.base_url + 'common/get_district.php');
  }

  getUpazila(district_code) {
    return this.http.get<any>(environment.base_url + 'common/get_upazila.php?name_code=' + district_code);
  }

  getHourlyForecast(upazila_code) {
    return this.http.get<any>('https://api.bdservers.site/upazila_forecast_steps_recent?SOURCE=BMDWRF&PCODE=' + upazila_code);
  }

  getDailyForecast(upazila_code) {
    return this.http.get<any>('https://api.bdservers.site/upazila_forecast_recent?SOURCE=BMDWRF&PCODE=' + upazila_code);
  }

  getDailyForecastForAlert() {
    let pcode = "PCODE=302602&PCODE=603602&PCODE=457204&PCODE=304802&PCODE=302604&PCODE=603605&PCODE=304806&PCODE=453907&PCODE=302605&PCODE=603611&PCODE=457209&PCODE=508811&PCODE=304811&PCODE=554906&PCODE=302606&PCODE=609018&PCODE=302608&PCODE=302609&PCODE=554908&PCODE=508827&PCODE=609023&PCODE=554909&PCODE=603626&PCODE=609027&PCODE=302610&PCODE=302611&PCODE=302612&PCODE=609029&PCODE=453915&PCODE=302614&PCODE=302616&PCODE=609032&PCODE=302618&PCODE=609033&PCODE=457218&PCODE=553221&PCODE=553224&PCODE=302624&PCODE=553230&PCODE=302626&PCODE=603644&PCODE=302628&PCODE=304827&PCODE=453929&PCODE=304833&PCODE=609047&PCODE=609050&PCODE=453936&PCODE=302629&PCODE=302632&PCODE=302630&PCODE=302633&PCODE=457240&PCODE=508844&PCODE=302634&PCODE=304842&PCODE=304845&PCODE=508850&PCODE=457247&PCODE=302638&PCODE=457238&PCODE=302636&PCODE=302637&PCODE=304849&PCODE=302640&PCODE=304854&PCODE=554952&PCODE=603668&PCODE=302642&PCODE=457256&PCODE=453958&PCODE=603671&PCODE=453961&PCODE=302648&PCODE=304859&PCODE=302650&PCODE=457263&PCODE=302654&PCODE=603677&PCODE=554961&PCODE=302662&PCODE=457274&PCODE=302663&PCODE=304876&PCODE=304879&PCODE=553267&PCODE=302664&PCODE=302665&PCODE=554918&PCODE=457283&PCODE=554977&PCODE=302666&PCODE=302667&PCODE=554979&PCODE=508861&PCODE=302668&PCODE=553282&PCODE=553288&PCODE=453985&PCODE=302672&PCODE=302674&PCODE=302675&PCODE=508867&PCODE=302680&PCODE=302676&PCODE=508878&PCODE=609086&PCODE=609089&PCODE=553291&PCODE=302688&PCODE=609092&PCODE=304892&PCODE=508889&PCODE=302690&PCODE=302692&PCODE=302693&PCODE=554994&PCODE=508894&PCODE=302696&PCODE=302695";
    return this.http.get<any>('https://api.bdservers.site/upazila_forecast_recent?SOURCE=BMDWRF&' + pcode);
  }

  getHeatwaveDistrictList(parameter) {
    return this.http.get<any>( environment.base_url + 'common/get_heatwave_district_list.php?parameter=' + parameter);
  }

  
  getDistrictAlert(parameter, fdate) {
    if(fdate == "") {
      // console.log("getDistrictAlert", environment.base_url + 'common/get_alert_district.php?parameter=' + parameter)
      return this.http.get<any>( environment.base_url + 'common/get_alert_district.php?parameter=' + parameter);
    } else {
      return this.http.get<any>( environment.base_url + 'common/get_alert_district.php?parameter=' + parameter + "&fdate=" + fdate);
    }
  }

  getUpazilaAlert(district, parameter, fdate) {
    if(fdate=="") {
      // console.log("getUpazilaAlert",environment.base_url + 'common/get_alert_upazila.php?district_id=' + district + '&parameter=' + parameter )
      return this.http.get<any>( environment.base_url + 'common/get_alert_upazila.php?district_id=' + district + '&parameter=' + parameter );
    } else {
      return this.http.get<any>( environment.base_url + 'common/get_alert_upazila.php?district_id=' + district + '&parameter=' + parameter  + "&fdate=" + fdate);
    }
  }

  getUpazilaValue(district, parameter) {

    console.log(environment.base_url + 'common/get_alert_value.php?upazila_id=' + district + '&parameter=' + parameter );
    return this.http.get<any>( environment.base_url + 'common/get_alert_value.php?upazila_id=' + district + '&parameter=' + parameter );
  }

  getDistrictTHIAlert(){
    return this.http.get<any>( environment.base_url + "common/get_alert_district_2.php?parameter=10");
  }
  
  
  getStationList(){
    return this.http.get<any>( environment.base_url + "common/bais/get_station_list.php");
  }
  
  getBaisCorrectedData(station_id, forecast_date){
    return this.http.get<any>( environment.base_url + "common/bais/get_station_data.php?station_id="+ station_id +"&forecast_date=" + forecast_date);
  }

  // @Sajib
 
  // readonly APIUrl="http://127.0.0.1:8000"; 

  readonly APIUrl=" https://api-sajib.bdservers.site"; 
 
  // readonly APIUrl="http://114.31.28.234:8501"; 

  private valueSource$=new BehaviorSubject(20);
  currentValue$=this.valueSource$.asObservable();

  getDailyMaxMin() {
    return this.http.get<any>(this.APIUrl+'/forecast/daily-max-min');
  }

  changeValue(value: number) {
    this.valueSource$.next(value);
  }

  showContent(){
    return this.valueSource$.getValue()
  }

  returnUpazilaForecastDaily(){

    return this.http.get<any>(this.APIUrl+'/forecast/daily-forecast/')
  }

  forecast_step_by_paramter(parameter_id){
    return this.http.get<any>(this.APIUrl+'/forecast/forecast-step-by-parameter/'+parameter_id+'/')
  }

  forecast_step_by_upazila_paramter(upazila_id,parameter_id){
    return this.http.get<any>(this.APIUrl+'/forecast/forecast-step-by-upazila-parameter/'+upazila_id+'/'+parameter_id+'/')
  }


  forecast_by_paramter(parameter_id){
    return this.http.get<any>(this.APIUrl+'/forecast/forecast-by-parameter/'+parameter_id+'/')
  }

  returnZilaGeo(){
    return this.http.get<any>('/assets/geojson/district_shape.geojson');
  }

  returnUpazilaGeo(){
    return this.http.get<any>('/assets/geojson/upazilaShape.json');
    // return this.http.get<any>('/assets/geojson/upazila.geojson');
  }

  getThiData() {
    return this.http.get<any>(this.APIUrl + '/forecast/daily-max-min');

  }

  getDailyThiData() {
    return this.http.get<any>(this.APIUrl + '/forecast/daily-thi-forecast');

  }
}

