<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink=""><i class="feather icon-home"></i></a></li>
      <li class="breadcrumb-item"><a routerLink="/dashboard">Dashboard</a></li>
      <li class="breadcrumb-item active" aria-current="page">Special Bulletin</li>
    </ol>
</nav>

<div class="row justify-content-center">
    <div class="col-md-4">
        <div class="card">
            <div class="card-header text-center">
                <h5 class="mb-0">Flood Bulletin</h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                    <label class="m-0">Forecast Date:</label>
                    <input type="date" class="form-control pl-0 pr-0" (ngModelChange)="dateChangeEvent($event)">
                </div>
                <div class="form-group">
                    <label class="m-0">Select Districts:</label>
                    <!-- <select class="form-control pl-0 pr-0" [(ngModel)]="myDistrict"> -->
                        <option value="">Select Districts</option>
                        <!-- <ng-container *ngFor="let item of districtList" >
                            <option value="{{item.district_id}}" *ngIf="item.alert>1">{{item.district_name}}</option>
                        </ng-container> -->
                    <!-- </select> -->
                </div>
                <button type="submit" class="btn btn-block btn-primary" >Generate Bulletin in English</button>
                <button type="submit" class="btn btn-block btn-success" >Generate Bulletin in Bangla</button>
            </div>
        </div>
    </div>
</div>