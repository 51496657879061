import { Directive,ElementRef } from '@angular/core';

@Directive({
  selector: '[appCustomDirective]',
})
export class CustomDirectiveDirective {

  constructor(private elRef:ElementRef) { 

    console.log(this.elRef.nativeElement);
  }

}
